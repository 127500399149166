import React from 'react'
import useDataLayer from '../hooks/useDataLayer';

import "../scss/ContactForm.scss"
import { Checkbox } from 'semantic-ui-react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios'

class ContactForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            call: false,
            text: false,
            checkEmail: false,
            first: '',
            last: '',
            email: '',
            phone: '',
            comments: '',
            id: this.props.dealer.id
        }

        this.handleCheckedInput = this.handleCheckedInput.bind(this)
        this.handleTextInput = this.handleTextInput.bind(this)

        this.response = React.createRef()
    }

    handleCheckedInput(e, data) {
        this.setState({
            [data.name]: data.checked
        })
    }

    handleTextInput(name, value) {
        this.setState({
            [name]: value
        })
    }

    checkValid(data) {
        return new Promise((resolve, reject) => {
            try {
                let required = ['firstName', 'lastName', 'email', 'phoneNumber']
                let found = []
    
                required.forEach(req => {
                    if (data[req] === '') {
                        this.response.current.innerText = 'Please fill out all required form inputs.'
                        this.response.current.classList = 'contact-failure'
        
                        document.getElementById(req).classList.add('contact-required')
    
                        found.push(req)
                    }
                })    
    
                resolve(found)    
            }

            catch (err) {
                reject(err)
            }
        })
    }

    async submitForm(e) {
        this.setState({
            loading: true
        })

        e.preventDefault()

        let data = {}
        data.call = this.state.call
        data.text = this.state.text
        data.checkEmail = this.state.checkEmail
        data.firstName = this.state.first
        data.lastName = this.state.last
        data.email = this.state.email
        data.phoneNumber = this.state.phone
        data.comments = this.state.comments
        data.dealerId = this.state.id
        data.siteId = this.props.site_id

        if (this.props.car) {
            data.vehicle = this.props.car
            data.vehicle.IsNew = true
        }

        let missing = await this.checkValid(data)

        if (missing.length > 0) {
            this.setState({
                loading: false
            })

            return
        }

        axios.post(this.props.lead_url, JSON.stringify(data)) // this needs an actual backend link
            .then(res => {
                this.props.pushToDataLayer("form-submission", res)
                this.props.pushToDataLayer("contact", res)

                this.response.current.innerText = 'Success! The message has been sent!'
                this.response.current.classList = 'contact-success'

                this.setState({
                    loading: false
                })        
            })
            .catch(err => {
                console.log(err) 

                this.setState({
                    loading: false
                })        

                this.response.current.innerText = 'Uh oh! Something went wrong, please try again!'
                this.response.current.classList = 'contact-failure'
            })
    }

    render() {
        console.log(this.props.lead_url)
        return (
            <div className="contact-page-root">
                <div className="contact-page-formCont">
                    <div className="contact-page-inputCont">
                        <input className="contact-page-input" id="firstName" name="first" placeholder="First Name *" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                        <input className="contact-page-input" id="lastName" name="last" placeholder="Last Name *" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-page-inputCont">
                        <input className="contact-page-input" id="email" name="email" placeholder="Email Address *" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                        <input className="contact-page-input" id="phoneNumber" name="phone" placeholder="Phone *" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-page-inputCont">
                        <textarea className="contact-page-input" name="comments" placeholder="Comments" onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-page-howShould">How should we contact you?</div>
                    <div className="contact-page-inputCont contact-page-checks">
                        <Checkbox name="call" onChange={(e, data) => this.handleCheckedInput(e, data)} label={<label className="contact-page-label">CALL</label>} />
                        <Checkbox name="text" onChange={(e, data) => this.handleCheckedInput(e, data)} label={<label className="contact-page-label">TEXT</label>} />
                        <Checkbox name="checkEmail" onChange={(e, data) => this.handleCheckedInput(e, data)} label={<label className="contact-page-label">EMAIL</label>} />
                    </div>
                    <div className="contact-page-inputCont contact-page-border">
                        <a href={this.props.dealer.privacy} style={{ color: this.props.theme.colors.accent1 }} className="contact-page-privacy"><i className="las la-lock"></i>PRIVACY POLICY</a>
                        <button className="contact-page-button" style={{ backgroundColor: this.props.theme.colors.accent1 }} onClick={(e) => this.submitForm(e)}>{this.state.loading === false ? 'SEND MESSAGE' : <Loader type="ThreeDots" color="white" height={12} width={12}/>}</button>
                    </div>
                    <div className="contact-page-response" ref={this.response}></div>
                    <div className="contact-page-disclaimer" dangerouslySetInnerHTML={{ __html: this.props.dealer.disclaimer }}></div>
                </div>
            </div>
        )
    }
}

function ContactFormWrapped (props) {
    const pushToDataLayer = useDataLayer()
    return (
        <ContactForm pushToDataLayer={pushToDataLayer} {...props}/>
    )
}

export default ContactFormWrapped