import React from 'react'
import ContactForm from './ContactForm'
import BottomMap from './BottomMap'
import queryString from 'query-string'
import "../scss/ContactSwitch.scss"

class ContactSwitch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            dealers: [],
            index: 0
        }

        this.viewRoot = React.createRef()
        this.renderView = this.renderView.bind(this)
    }

    componentDidMount() {
        this.setState({
            dealers: this.props.group.dealers
        })

        this.checkQuery(this.props.group.dealers) // checking for url params
    }

    checkQuery(group) {
        let params = queryString.parse(this.props.location.search)

        if (params.dealer !== '') {
            let findDealer = group.find(dealer => dealer.id === params.dealer)

            if (findDealer !== undefined) {
                this.setState({
                    index: group.indexOf(findDealer)
                })
            }
        }
    }

    renderView(index, e) {
        let button = e
        let allButtons = document.getElementsByClassName('switch-parent')

        for (let x = 0; x < allButtons.length; x++) {
            allButtons[x].classList.remove('underline')
        }

        button.parentElement.classList.toggle('underline')

        this.setState({
            index: index
        })
    }

    mapHours(arr) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

        let hours = days.map((day, i) => {
            return arr[i] && (
                <div key={day} className="view-eachHour">
                    <div className="view-day">{day}: </div>{arr[i].time}
                </div>
            )
        })

        return hours
    }

    renderHours(title, hoursArray) {
        return hoursArray.length > 0 && (
            <div className="view-hoursBox">
                <div className="view-sales">{title}:</div>
                <div className="view-hours">
                    {this.mapHours(hoursArray)}
                </div>
            </div>
        )
    }

    render() {
        let buttons = this.state.dealers.map((dealer, i) => {
            return (
                <div className={i === this.state.index ? "switch-parent underline" : "switch-parent"} key={i}>
                    <button onClick={(e) => this.renderView(i, e.target)} className="switch-button" style={{ color: this.props.theme.colors.accent1 }}>{dealer.dealerName}</button>
                </div>
            )
        })

        let dealerViews = this.state.dealers.map((dealer, i) => {
            return (
                <div key={i} name={dealer.dealerName} className="view-dealer" id={dealer.id}>
                    <div className="view-topInfo">
                        <div className="view-topHeader">We Would Love to Hear From You</div>
                        <div className="view-topText">Have a question? Want to talk something over? Contact us with any questions, concerns or information requests. At {dealer.dealerName}, your satisfaction is important to us. We’re happy to hear from our customers and we will get back to you real quick. Or feel free to stop in!</div>
                    </div>
                    <ContactForm dealer={dealer} theme={this.props.theme} car={this.props.car} site_id={this.props.site_id} lead_url={this.props.lead_url} />
                    <div className="view-topInfo">
                        <div className="view-infoBox">
                            <div className="view-column">
                                <div className="view-boxFlex">
                                    <i className="las la-phone-volume" style={{ color: this.props.theme.colors.accent1, fontSize: '30px' }}></i>
                                    <div className="view-boxHead" style={{ color: this.props.theme.colors.accent1 }}>Phone</div>
                                </div>
                                {dealer.salesNumber.length > 0 ? <a href={'tel:' + dealer.salesNumber} className="view-phoneBox"><div className="view-phoneStrong">Sales:</div> {dealer.salesNumber}</a> :<></>}
                                {dealer.serviceNumber.length > 0 ?<a href={'tel:' + dealer.serviceNumber} className="view-phoneBox"><div className="view-phoneStrong">Service:</div> {dealer.serviceNumber}</a>:<></>}
                                {dealer.partsNumber.length > 0 ?<a href={'tel:' + dealer.partsNumber} className="view-phoneBox"><div className="view-phoneStrong">Parts:</div> {dealer.partsNumber}</a>:<></>}
                                {dealer.bodyShopNumber.length > 0 ?<a href={'tel:' + dealer.bodyShopNumber} className="view-phoneBox"><div className="view-phoneStrong">Body Shop:</div> {dealer.bodyShopNumber}</a>:<></>}
                                {dealer.financeNumber.length > 0 ?<a href={'tel:' + dealer.financeNumber} className="view-phoneBox"><div className="view-phoneStrong">Finance:</div> {dealer.financeNumber}</a>:<></>}
                            </div>
                            <div className="view-column">
                                <div className="view-boxFlex">
                                    <i className="las la-clock" style={{ color: this.props.theme.colors.accent1, fontSize: '30px' }}></i>
                                    <div className="view-boxHead" style={{ color: this.props.theme.colors.accent1 }}>Hours</div>
                                </div>
                                {this.renderHours("Sales", dealer.salesHours)}
                                {this.renderHours("Service", dealer.serviceHours)}
                                {this.renderHours("Parts", dealer.partsHours)}
                                {this.renderHours("Body Shop", dealer.partsHours)}
                                {this.renderHours("Finance", dealer.financeHours)}
                            </div>
                            <div className="view-column">
                                <div className="view-boxFlex">
                                    <i className="las la-map-marker" style={{ color: this.props.theme.colors.accent1, fontSize: '30px' }}></i>
                                    <div className="view-boxHead" style={{ color: this.props.theme.colors.accent1 }}>Location</div>
                                </div>
                                <div className='view-sales' style={{ marginBottom: '10px' }}>{dealer.dealerName}</div>
                                <div className="view-phoneBox">{dealer.dealerAddress}</div>
                                <a href={dealer.directions} className="view-button">GET DIRECTIONS</a>
                            </div>
                        </div>
                    </div>
                    <BottomMap data={dealer} theme={this.props.theme} />
                </div>
            )
        })

        return (
            <div>
                <div className="switch-buttonCont">
                    {buttons}
                </div>
                <div className="view-root" ref={this.viewRoot}>
                    {dealerViews[this.state.index]}
                </div>
            </div>
        )
    }
}

export default ContactSwitch