import React from "react"
import Layout from '../components/layout'
import ContactSwitch from '../components/ContactSwitch'
import '../scss/Contact.scss'
import '../scss/Specials.scss'
import SEO from '../components/SEO'
import HTML from '../components/HTML'


function Contact(props) {
    const { pageContext } = props
    const { theme, dealerInfo, site_id, pageContent, lead_url } = pageContext
    
    return (
        <Layout>
            <HTML key={"HTML"} data={pageContent.HTML} />
            <div className="topHeader" style={{ backgroundImage: "url('https://cdn.mxsapi.com/Symes/contact.png')" }}></div>
            <div className='middle-main'>Contact Us</div>
            <ContactSwitch key={"ContactSwitch"} group={dealerInfo} theme={theme} car={props.location.state} site_id={site_id} location={props.location} lead_url={lead_url} />
            <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />
        </Layout>
    )
}

export default Contact
